export default {
	slot1: "4325205813", //第一个
	slot2: "1699042471", //第二个
	slot3: "5446715797", //第三个
	client: 'ca-pub-8908317842209223',
	scriptUrl: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223",
	cid: 97, //可不修改

	firebaseConfig: {
		apiKey: 'AIzaSyCsac4dnFfhSrTG4yLVjQwqEkR6moymIKM',
		authDomain: 'mumusiic-c62a1.firebaseapp.com',
		projectId: 'mumusiic-c62a1',
		storageBucket: 'mumusiic-c62a1.appspot.com',
		messagingSenderId: '830411448500',
		appId: '1:830411448500:web:9acc97b290f3c47ae737e1',
		measurementId: 'G-C3DEJN4RH9',
	},
}
